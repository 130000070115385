import { graphql, Link } from 'gatsby'
import { facebook, linkedin, twitter } from 'images'
import marked from 'marked'
import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Layout from '../layouts'

class PartnerPage extends Component {
    render() {
        const { edges } = this.props.data.allContentfulPartners
        const { locations } = edges[0].node.locations
        const allContent = edges[0].node.longDescription.childMarkdownRemark.html

        var photos = ''

        if (edges[0].node.photos) {
            photos = (
                <div className="col-12 no-pad image-container">
                    <img src={edges[0].node.photos[0].file.url} alt="Logo" />;
                </div>
            )
        }

        const email = 'mailto:' + edges[0].node.email
        const metaTitle =
            'Vind een accountant of boekhouder | ' +
            edges[0].node.name +
            ', partner van Employes'
        const metaDescription =
            'Op zoek naar een goed administratiekantoor of accountant die ook werkt met Employes? Bekijk ' +
            edges[0].node.name

        return (
            <Layout location={this.props.location}>
                <main className="accountants-overview animated">
                    <Helmet>
                        <title>{metaTitle}</title>
                        <meta name="description" content={metaDescription} />
                        <meta itemprop="name" content={metaTitle} />
                        <meta
                            itemprop="description"
                            content={metaDescription}
                        />
                        <meta itemprop="image" content="/static/meta-img.png" />
                    </Helmet>

                    <div className="section padding-l">
                        <div className="backdrop-detail-page"></div>

                        <div className="container-sm">
                            <div className="grid yg center">
                                <div className="col-4 side-bar">
                                    <div className="logo-container-detail-page margin-l-top">
                                        <img
                                            src={edges[0].node.logo.file.url}
                                            alt="Logo"
                                        />
                                    </div>

                                    <h6>Vestigingen</h6>
                                    {edges[0].node.locations.map((location) => (
                                        <p
                                            key={location.id}
                                            className="margin-xs-bottom"
                                        >
                                            {location.address} <br />
                                            {location.postcode} {location.city}
                                        </p>
                                    ))}

                                    <h6>Contactgegevens</h6>
                                    <p className="margin-s-bottom">
                                        tel. {edges[0].node.phone}
                                    </p>

                                    <div className="margin-s-bottom">
                                        <a
                                            className="btn secondary sm flex margin-xs-bottom"
                                            href={email}
                                            target="_blank"
                                        >
                                            Stuur e-mail
                                        </a>
                                        <a
                                            className="btn secondary sm flex"
                                            href={edges[0].node.website}
                                            target="_blank"
                                        >
                                            Bekijk website
                                        </a>
                                    </div>

                                    <div className="social-media-icons">
                                        {edges[0].node.linkedin ? (
                                            <div className="btn-circle-social">
                                                <a
                                                    className=""
                                                    href={
                                                        edges[0].node.linkedin
                                                    }
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={linkedin}
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        {edges[0].node.twitter ? (
                                            <div className="btn-circle-social">
                                                <a
                                                    className=""
                                                    href={edges[0].node.twitter}
                                                    target="_blank"
                                                >
                                                    <img src={twitter} alt="" />
                                                </a>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        {edges[0].node.facebook ? (
                                            <div className="btn-circle-social">
                                                <a
                                                    className=""
                                                    href={
                                                        edges[0].node.facebook
                                                    }
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={facebook}
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>

                                <div className="col-8">
                                    <div className="header padding-m">
                                        <Link
                                            to="/partners/"
                                            className="link mulberry"
                                        >
                                            Overzicht
                                        </Link>{' '}
                                        <br />
                                        <h2 className="no-mar">
                                            {edges[0].node.name}
                                        </h2>
                                        <p>
                                            {
                                                edges[0].node.shortDescription
                                                    .shortDescription
                                            }
                                        </p>
                                    </div>

                                    <div className="padding-xl">
                                        <div className="text alinea margin-m-bottom">
                                            <h4>Over {edges[0].node.name} </h4>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: allContent,
                                                }}
                                            ></div>
                                        </div>

                                        {photos}

                                        <div className="grid yg text">
                                            {edges[0].node.accountingPackages
                                                ?.length && (
                                                <div className="col-6">
                                                    <h6>Boekhoudpakketten</h6>
                                                    <ul>
                                                        {edges[0].node.accountingPackages.map(
                                                            (
                                                                accountingPackage
                                                            ) => (
                                                                <li>
                                                                    <span className="checkmark-container blue">
                                                                        <svg
                                                                            width="13"
                                                                            height="10"
                                                                            viewBox="0 0 13 10"
                                                                        >
                                                                            <polyline
                                                                                fill="none"
                                                                                className="checkmark"
                                                                                strokeWidth="2"
                                                                                points="17.532 8 10.02 15.512 7 12.492"
                                                                                transform="translate(-6 -7)"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    {
                                                                        accountingPackage
                                                                    }
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                            <div className="col-6">
                                                <h6>Diensten</h6>
                                                <ul>
                                                  {edges[0].node
                                                      .salarisadministratie ? (
                                                      <li>
                                                          <span className="checkmark-container blue">
                                                              <svg
                                                                  width="13"
                                                                  height="10"
                                                                  viewBox="0 0 13 10"
                                                              >
                                                                  <polyline
                                                                      fill="none"
                                                                      className="checkmark"
                                                                      strokeWidth="2"
                                                                      points="17.532 8 10.02 15.512 7 12.492"
                                                                      transform="translate(-6 -7)"
                                                                  />
                                                              </svg>
                                                          </span>
                                                          Salarisadministratie
                                                      </li>
                                                  ) : (
                                                      ''
                                                  )}
                                                    {edges[0].node
                                                        .ibAangifte ? (
                                                        <li>
                                                            <span className="checkmark-container blue">
                                                                <svg
                                                                    width="13"
                                                                    height="10"
                                                                    viewBox="0 0 13 10"
                                                                >
                                                                    <polyline
                                                                        fill="none"
                                                                        className="checkmark"
                                                                        strokeWidth="2"
                                                                        points="17.532 8 10.02 15.512 7 12.492"
                                                                        transform="translate(-6 -7)"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            IB-aangifte
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {edges[0].node
                                                        .btwAangifte ? (
                                                        <li>
                                                            <span className="checkmark-container blue">
                                                                <svg
                                                                    width="13"
                                                                    height="10"
                                                                    viewBox="0 0 13 10"
                                                                >
                                                                    <polyline
                                                                        fill="none"
                                                                        className="checkmark"
                                                                        strokeWidth="2"
                                                                        points="17.532 8 10.02 15.512 7 12.492"
                                                                        transform="translate(-6 -7)"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            Btw-aangifte
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {edges[0].node
                                                        .vpbAangifte ? (
                                                        <li>
                                                            <span className="checkmark-container blue">
                                                                <svg
                                                                    width="13"
                                                                    height="10"
                                                                    viewBox="0 0 13 10"
                                                                >
                                                                    <polyline
                                                                        fill="none"
                                                                        className="checkmark"
                                                                        strokeWidth="2"
                                                                        points="17.532 8 10.02 15.512 7 12.492"
                                                                        transform="translate(-6 -7)"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            Vpb-aangifte
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {edges[0].node
                                                        .jaarverslagen ? (
                                                        <li>
                                                            <span className="checkmark-container blue">
                                                                <svg
                                                                    width="13"
                                                                    height="10"
                                                                    viewBox="0 0 13 10"
                                                                >
                                                                    <polyline
                                                                        fill="none"
                                                                        className="checkmark"
                                                                        strokeWidth="2"
                                                                        points="17.532 8 10.02 15.512 7 12.492"
                                                                        transform="translate(-6 -7)"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            Jaarverslagen
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Layout>
        )
    }
}

export default PartnerPage

export const partnerQuery = graphql`
    query rightPartner($slug: String!) {
        allContentfulPartners(filter: { slug: { eq: $slug } }) {
            edges {
                node {
                    id
                    node_locale
                    name
                    shortDescription {
                        id
                        shortDescription
                    }
                    longDescription {
                      childMarkdownRemark{
                        html
                      }
                    }
                    slug
                    logo {
                        id
                        file {
                            url
                        }
                    }
                    accountingPackages
                    phone
                    email
                    website
                    twitter
                    linkedin
                    facebook
                    photos {
                        id
                        file {
                            url
                        }
                    }
                    locations {
                        id
                        address
                        postcode
                        city
                    }
                    ibAangifte
                    btwAangifte
                    vpbAangifte
                    jaarverslagen
                    salarisadministratie
                }
            }
        }
    }
`
